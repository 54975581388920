$(document).ready(function() {
  // インラインフレーム内で開いている場合は親ウィンドウに再ログインメッセージモーダルを表示
  if(window != window.parent){
    if(window.parent.$('#forceLoginModal').length == 0){
      modalShow();
    }
    document.body.innerHTML = "<p>セッションが切れました</p>";
  }
});

function modalHtml(){
  return `
  <div id="forceLoginModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
  <div class="modal-dialog modal-lg" role="document" >
    <div class="modal-content"   style="height:80vh">
      <div class="modal-header">
        <h5 class="modal-title">再ログインが必要です</h5>
      
      </div>
      <div class="modal-body" style="height:60vh;">
        <p style="position: absolute;top:50%;display:inline-block;left:25%;">セッションが切れていますのでブラウザを更新して再ログインしてください。</p>
      </div>  
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onclick="location.href='/manager/user_session/new'">ログイン画面へ</button>
      </div>    

    </div>
  </div>
</div>`;
}

function modalShow(){
  window.parent.$("body").append(modalHtml());
  //キーボード操作を有効にする(F5を使いたいため)
  window.parent.$('#forceLoginModal').modal({keyboard: true, backdrop: 'static'});
}
